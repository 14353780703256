/* .carddiv{
    text-align: center; 
}; */

.grid-container {
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr; */
    grid-template-columns: 70px 70px 70px 70px 70px;
    /* padding: 10px; */
    grid-gap: 10px;
  
    
    
  }

  .apexcharts-menu {
    background: black;
    position: absolute;
    top: 100%;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 3px;
    right: 10px;
    opacity: 0;
    min-width: 110px;
    transition: .15s ease all;
    pointer-events: none;
}

.apexcharts-menu-item {
  padding: 6px 7px;
  font-size: 12px;
  cursor: pointer;
  color: rgb(0, 0, 0);
}


.apexcharts-tooltip{
  color: #000;
}

.footer-fix{
  position: fixed;
  bottom: 25px; 
  right: 10px; 
  z-index: 999;
  border-radius: 50%;
}

.ion-footer{
  position: fixed;
  height: 50px;
  bottom: 20px;
  width: 90%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  
background: rgba(101, 101, 101, 0.08);
/* border-radius: 16px; */
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(6.5px);
-webkit-backdrop-filter: blur(6.5px);
border: 0.5px solid rgba(194, 194, 194, 0.205);
}

.footerbutton{
  --border-color: #9c9c9c;
  --ripple-color: rgb(156, 156, 156);

  --background: #a5a5a500;
  --background-hover: #000000;
  --background-activated: #000000;
}

.md body.dark {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 18,18,18;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255,255,255;
  --ion-border-color: #222222;
  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;
  --ion-item-background: #1e1e1e;
  --ion-toolbar-background: #1f1f1f;
  --ion-tab-bar-background: #1f1f1f;
  --ion-card-background: #1e1e1e;
}

.apexcharts-canvas ::-webkit-scrollbar {
    width: 200px;
  }

  foreignObject {
    
    /* x: 0;
    y: 0; */
    width: 350;
    height: 217.391;
    
}
  
  .grid-item{
    padding: 10px 10px 0px 0px ;
    text-align: center;
    font-size: small;
  }
  .grid-item-name{
    /* padding: 10px 10px 0px 0px ; */
    text-align: center;
    font-size: small;
    font-weight: 700;
  }
  
  .card-field-value{
    font-size: medium;
    text-align: center;
  }

  .card-field-label{
    font-size: small;
    text-align: center;
  }
/* ------------------------------------------ */

.shopname {
  cursor: pointer;
  position: absolute;
  top: 0.75rem;
  left: 2.44rem;
  font-size: 1rem;
  text-align: left;
  display: flex;
  align-items: center;
  width: 18.88rem;
  height: 1.75rem;
  
}
.vector-icon {
  position: relative;
  width: 1.35rem;
  height: 1.35rem;
}
.vector-wrapper {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 0.94rem;
  left: 16.06rem;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  
}


.maincard {
  position: absolute;
  top: 3rem;
  left: 1rem;
  border-radius: var(--br-sm);
  background-color: var(--color-gray);
  width: 22.31rem;
  height: 11.06rem;
}
.div1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.13rem;
  height: 1.38rem;
  flex-shrink: 0;
}
.actualvalue,
.div1,
.div2,
.predicted {
  position: relative;
  font-weight: 600;
  justify-content: space-between;
}
.actualvalue {
  cursor: pointer;
  color: var(--color-limegreen);
}
.div2,
.predicted {
  font-size: var(--font-size-xl);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  height: 1.13rem;
  flex-shrink: 0;
}
/* .predicted {
  cursor: pointer;
  width: 3rem;
  height: 1.06rem;

} */
.span4 {
  color: var(--color-orange);
  /* display: flex; */
  /* align-items: center; */
  position: relative;
  justify-content: space-between;
  

}
.mainpercentage-txt {
  /* line-break: anywhere; */
  width: 3rem;
  justify-content: space-between;
}
.mainpercentage {
  cursor: pointer;
  position: relative;
  font-size: var(--font-size-smi);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 8rem;
  flex-wrap: wrap;
  /* flex-shrink: 0; */
}
.mainnumbers,
.mainnumbers1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.mainnumbers1 {
  width: auto;
  height: 1.44rem;
  padding: 0 0 0.06rem;
  box-sizing: border-box;
  justify-content: space-between;
  gap: var(--gap-11xs);
}
.mainnumbers {
  position: absolute;
  top: 8.38rem;
  left: 2.38rem;
  justify-content: center;
  font-size: var(--font-size-11xl);
}
.today2 {
  margin: 0;
}
.today1 {
  top: 3.88rem;
  left: 10.5rem;
  color: #6ca4f8;
  text-align: left;
  display: inline-block;
  width: 2.94rem;
  height: 1.06rem;
}
.percent,
.sale-predicted1,
.today1 {
  cursor: pointer;
  position: absolute;
  font-weight: 500;
}
.sale-predicted1 {
  top: 6.5rem;
  left: 2.44rem;
  text-align: left;
  display: inline-block;
  width: 8.19rem;
  height: 1.06rem;
}
.percent {
  top: 4.06rem;
  left: 14.40rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 3.13rem;
  height: 0.88rem;
}
.arrow-icon {
  position: absolute;
  top: 4.04rem;
  left: 13.73rem;
  width: 0.46rem;
  height: 0.9rem;
}
.uniticon {
  position: relative;
  width: 1.73rem;
  height: 1.73rem;
  object-fit: cover;
}
.unitvalue {
  cursor: pointer;
  position: relative;
  font-weight: 600;
  width: 2rem;
  height: 1.3rem;
  flex-shrink: 0;
}
.unit-frame,
.units1,
.unitvalue {
  display: flex;
  align-items: center;
  justify-content: center;
}
.units1 {
  position: relative;
  font-size: var(--font-size-xs);
  font-weight: 500;
  width: 2.16rem;
  height: 1.08rem;
  flex-shrink: 0;
}
.unit-frame {
  flex-direction: row;
}
.billicon,
.billvalue {
  position: relative;
  width: 1.3rem;
  height: 1.3rem;
  display: flex;
  justify-content: space-between;
}
.billicon {
  object-fit: fill;
}
.billvalue {
  /* cursor: pointer; */
  font-weight: 600;
  flex-shrink: 0;
}
.billframe,
/* .bills1, */
.billvalue {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}
.bills1 {
  position: relative;
  font-size: var(--font-size-xs);
  font-weight: 500;
  width: 2rem;
  height: 1.08rem;
  /* left: 1.4rem; */
}
.billframe {
  /* flex-direction: row; */
  gap: 1rem;
  
}
.visitorsvalue {
  cursor: pointer;
  position: relative;
  font-weight: 600;
  width: 2.06rem;
  height: 1.31rem;
  flex-shrink: 0;
}
.unit-frame-parent,
.visitors2,
.visitorsframe,
.visitorsvalue {
  display: flex;
  align-items: center;
}
.visitors2 {
  position: relative;
  font-size: var(--font-size-2xs);
  font-weight: 600;
  width: 2.56rem;
  height: 1.06rem;
  /* left:1.3rem */
}
.unit-frame-parent,
.visitorsframe {
  flex-direction: row;
}
.visitorsframe {
  gap: var(--gap-7xs);
}
.unit-frame-parent {
  position: absolute;
  top: 11.69rem;
  left: 2.5rem;
  gap: 1.38rem;
}
.wtd {
  position: relative;
  font-weight: 500;
  width: 2.31rem;
  height: 1.06rem;
  flex-shrink: 0;
  color: #70a2ff;
}
.unit-frame1,
.unitvalue1,
.wtd {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.unitvalue1 {
  cursor: pointer;
  position: relative;
  font-weight: 600;
  color: var(--color-white);
  width: 4.06rem;
  height: 1.31rem;
  flex-shrink: 0;
}
.unit-frame1 {
  position: absolute;
  top: 3.75rem;
  left: 2.44rem;
  flex-direction: row;
  gap: var(--gap-11xs);
  color: #5A8BE3;
}
.graph-child {
  position: relative;
  width: 1.51rem;
  height: 1.46rem;
}
.graph {
  cursor: pointer;
  border: 0;
  padding: 0.75rem;
  background-color: var(--color-black);
  position: absolute;
  top: 3.63rem;
  left: 19.45rem;
  border-radius: var(--br-7xs);
  width: 3rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.vector-icon1 {
  position: relative;
  width: 1.25rem;
  height: 0.61rem;
}
.dropdown {
  cursor: pointer;
  border: 0;
  padding: 1.19rem 0.94rem;
  background-color: var(--color-black);
  position: absolute;
  top: 5rem;
  left: 19.24rem;
  border-radius: var(--br-7xs);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.codecard-child {
  top: 5.66rem;
  left: 0.97rem;
  border-top: 1px solid var(--color-grey);
  width: 17.5rem;
  height: 0.06rem;
}
.codecard-child,
.codecard-inner,
.codecard-item {
  position: absolute;
  box-sizing: border-box;
}
.codecard-item {
  top: 10.91rem;
  left: 0.97rem;
  border-top: 1px solid var(--color-black);
  width: 22.38rem;
  height: 0.06rem;
}
.codecard-inner {
  top: 2.97rem;
  left: 18.41rem;
  border-right: 1px solid var(--color-black);
  width: 0.06rem;
  height: 8rem;
}
.codecard {
  position: relative;
  /* background-color: red; */
  /* background-color: var(--color-black); */
  width: 390px;
  height: 15.25rem;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-mini);
  color: var(--color-white);
  font-family: var(--font-inter);
  /* Align self center */
}


/* -------------------GLOBAL CSS-------------------------- */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-inter: Inter;

  /* font sizes */
  --font-size-xs: 0.75rem;
  --font-size-4xs: 0.56rem;
  --font-size-mini: 0.94rem;
  --font-size-xl: 1.25rem;
  --font-size-2xs: 0.69rem;
  --font-size-smi: 0.81rem;
  --font-size-11xl: 1.88rem;

  /* Colors */
  --color-black: #000;
  --color-white: #fff;
  --color-cornflowerblue: #2f98f9;
  --color-orange: #ff9900;
  --color-limegreen: #02ca16;
  --color-gray: #1a1a1a;

  /* Gaps */
  --gap-11xs: 0.13rem;
  --gap-7xs: 0.38rem;
  --gap-9xs: 0.25rem;
  --gap-10xs: 0.19rem;

  /* Paddings */
  --padding-12xs-6: 0.04rem;

  /* border radiuses */
  --br-8xs: 5px;
  --br-2xs: 11px;
  --br-7xs: 6px;
  --br-sm: 14px;
}

/* ------------------------------ */
.tdspan{
  display: inline-flex;
align-items: center;
justify-items: center;
padding-left: 0.8rem;
}
.tdspan1st{
  display: inline-flex;
align-items: center;
justify-items: center;
}
.tdinnerspan{
  display: inline-flex;
align-items: center;
justify-items: center;
  padding-left: 0.4rem;
  }
  .tdspan1{
    display: inline-flex;
    align-items: center;
    justify-items: center;
      padding-left: 0.5rem;
      }
      .tdinnerspan2{
        font-size: small;
        padding-left: 0.3rem;
      }
     /* --------------------------------------- */
      input[type=text] {
        /* width: 100%; */
        padding: 12px 20px;
        margin: 8px;
        box-sizing: border-box;
        border: 1px solid #666666;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        /* outline: none; */
      }
      input[type=text]:focus {
        border: 2px solid #b5b5b5;
      }

/* -------------------------------------- */

.header {
  width: 100%;
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.header.fixed {
  position: fixed;
  background-color: white;
  height: 60px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  max-width: 200px;
  max-height: 60px;
  transition: all 0.3s ease;
}

.header.fade-in {
  animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.header.slide-in {
  animation: slideIn 0.5s ease-in;
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.oldData{
  width: 5rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
ion-content.background{
    --background: url("/public/loginbg.png") 0/100% ;
    
}
ion-input.logininput{
    --background: rgb(255, 255, 255);
    /* opacity: 10%; */
    margin-bottom: 20px;
    width: 300px;
    border-radius: 10px;
    --placeholder-color: rgb(0, 0, 0);
    opacity: 80%;
    color: black;
    text-indent: 10px;
}
/* :root {

    --ion-background-color: black;
    --ion-tab-bar-color: rgb(92, 123, 207);
    --ion-tab-bar-color-selected: rgb(0, 0, 0);
} */

ion-tab-bar {
/* 
	--background: rgb(255, 0, 0);
	box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.4); */
	border-radius: 16px !important;

	height: 50px;
	width: 90%;
	padding-top: 5px;
	padding-bottom: 5px;

	bottom: 20px;
	position: relative;
	margin: 0 auto !important;
	border-top: none;

	background: rgba(101, 101, 101, 0.08);
/* border-radius: 16px; */
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(6.5px);
-webkit-backdrop-filter: blur(6.5px);
border: 0.5px solid rgba(194, 194, 194, 0.205);
}

ion-tab-button {

    background-color: rgba(240, 248, 255, 0);
}